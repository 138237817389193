.cell {
    display: flex;
    align-items: center;

    svg {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
        fill: #1c1e21;
    }
}
