.searchByEventWrapper {
    :global {
        .MuiFormControl-root {
            margin-bottom: 0;
        }

        .MuiInputBase-root {
            font-weight: 400;
            font-size: 16px;
        }
    }
}
