.customizationWrapper {
    font-size: 16px;
    font-family: 'Ezlo Sans';
    padding: 1.5em 2.5em;
    position: relative;
}
._support-notification {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px 0;
}
.listHeader {
    padding-bottom: 30px;
    padding-top: 20px;
    border-bottom: 1px solid rgba(144, 160, 175, 0.15);
}
.listHeaderHeading {
    color: #3e46ae;
    font-weight: 700;
    margin: 0;
    font-size: 32px;
}

.meshbotCellIcon {
    display: flex;
    justify-content: left;
    margin-left: 10px;
    cursor: pointer;
    width: 100%;
    position: relative;
}
.meshbotCellIcon > svg {
    width: 20px;
    align-self: center;
}
.meshbotCellIcon > span{
    align-self: center;
    margin-left: 10px;
    margin-right: 3px;
}
.meshbotCellIcon > .icon {
    fill: #afafaf;
    color: #afafaf;
    height: 16px;
}
.meshbotsCellIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    position: relative;
}
.meshbotsCellIconType > svg {
    fill: #afafaf;
    align-self: center;
    width: 18px;
    margin-top: 15px;
}
.meshbotsCellIconType > span {
    align-self: center;
    margin-left: 10px;
}
.meshbotsCellIconTypelocal:hover::after{
    position: absolute;
    display: flex;
    content: 'Local MeshBot';
    background-color: #fff;
    font-size: 14px;
    top: 18px;
    border: 2px solid #eee;
    height: 30px;
    align-items: center;
    justify-content: center;
    padding: 5px;
    left: 2px;
}
.meshbotsCellIconTypecloud:hover::after {
    position: absolute;
    display: flex;
    content: 'Cloud MeshBot';
    background-color: #fff;
    font-size: 14px;
    top: 18px;
    border: 2px solid #eee;
    height: 30px;
    align-items: center;
    justify-content: center;
    padding: 5px;
    left: 2px;
}
