.flag {
    display: inline-block;
    height: 16px;
    width: 16px;
    vertical-align: text-top;
    line-height: 16px;
    background: url(../assets/images/flags.png) no-repeat;
}
.flag_African_Union {
    background-position: 0 -16px;
}
.flag_Arab_League {
    background-position: 0 -32px;
}
.flag_ASEAN {
    background-position: 0 -48px;
}
.flag_CARICOM {
    background-position: 0 -64px;
}
.flag_CIS {
    background-position: 0 -80px;
}
.flag_Commonwealth {
    background-position: 0 -96px;
}
.flag_England {
    background-position: 0 -112px;
}
.flag_European_Union,
.flag_eu {
    background-position: 0 -128px;
}
.flag_Islamic_Conference {
    background-position: 0 -144px;
}
.flag_Kosovo {
    background-position: 0 -160px;
}
.flag_NATO {
    background-position: 0 -176px;
}
.flag_Northern_Cyprus {
    background-position: 0 -192px;
}
.flag_Northern_Ireland {
    background-position: 0 -208px;
}
.flag_Olimpic_Movement {
    background-position: 0 -224px;
}
.flag_OPEC {
    background-position: 0 -240px;
}
.flag_Red_Cross {
    background-position: 0 -256px;
}
.flag_Scotland {
    background-position: 0 -272px;
}
.flag_Somaliland {
    background-position: 0 -288px;
}
.flag_Tibet {
    background-position: 0 -304px;
}
.flag_United_Nations {
    background-position: 0 -320px;
}
.flag_Wales {
    background-position: 0 -336px;
}
.flag_ad {
    background-position: 0 -352px;
}
.flag_ae {
    background-position: 0 -368px;
}
.flag_af {
    background-position: 0 -384px;
}
.flag_ag {
    background-position: 0 -400px;
}
.flag_ai {
    background-position: 0 -416px;
}
.flag_al {
    background-position: 0 -432px;
}
.flag_am {
    background-position: 0 -448px;
}
.flag_ao {
    background-position: 0 -464px;
}
.flag_aq {
    background-position: 0 -480px;
}
.flag_ar {
    background-position: 0 -496px;
}
.flag_as {
    background-position: 0 -512px;
}
.flag_at {
    background-position: 0 -528px;
}
.flag_au {
    background-position: 0 -544px;
}
.flag_aw {
    background-position: 0 -560px;
}
.flag_ax {
    background-position: 0 -576px;
}
.flag_az {
    background-position: 0 -592px;
}
.flag_ba {
    background-position: 0 -608px;
}
.flag_bb {
    background-position: 0 -624px;
}
.flag_bd {
    background-position: 0 -640px;
}
.flag_be {
    background-position: 0 -656px;
}
.flag_bf {
    background-position: 0 -672px;
}
.flag_bg {
    background-position: 0 -688px;
}
.flag_bh {
    background-position: 0 -704px;
}
.flag_bi {
    background-position: 0 -720px;
}
.flag_bj {
    background-position: 0 -736px;
}
.flag_bm {
    background-position: 0 -752px;
}
.flag_bn {
    background-position: 0 -768px;
}
.flag_bo {
    background-position: 0 -784px;
}
.flag_br {
    background-position: 0 -800px;
}
.flag_bs {
    background-position: 0 -816px;
}
.flag_bt {
    background-position: 0 -832px;
}
.flag_bw {
    background-position: 0 -848px;
}
.flag_by {
    background-position: 0 -864px;
}
.flag_bz {
    background-position: 0 -880px;
}
.flag_ca {
    background-position: 0 -896px;
}
.flag_cg {
    background-position: 0 -912px;
}
.flag_cf {
    background-position: 0 -928px;
}
.flag_cd {
    background-position: 0 -944px;
}
.flag_ch {
    background-position: 0 -960px;
}
.flag_ci {
    background-position: 0 -976px;
}
.flag_ck {
    background-position: 0 -992px;
}
.flag_cl {
    background-position: 0 -1008px;
}
.flag_cm {
    background-position: 0 -1024px;
}
.flag_cn {
    background-position: 0 -1040px;
}
.flag_co {
    background-position: 0 -1056px;
}
.flag_cr {
    background-position: 0 -1072px;
}
.flag_cu {
    background-position: 0 -1088px;
}
.flag_cv {
    background-position: 0 -1104px;
}
.flag_cy {
    background-position: 0 -1120px;
}
.flag_cz {
    background-position: 0 -1136px;
}
.flag_de {
    background-position: 0 -1152px;
}
.flag_dj {
    background-position: 0 -1168px;
}
.flag_dk {
    background-position: 0 -1184px;
}
.flag_dm {
    background-position: 0 -1200px;
}
.flag_do {
    background-position: 0 -1216px;
}
.flag_dz {
    background-position: 0 -1232px;
}
.flag_ec {
    background-position: 0 -1248px;
}
.flag_ee {
    background-position: 0 -1264px;
}
.flag_eg {
    background-position: 0 -1280px;
}
.flag_eh {
    background-position: 0 -1296px;
}
.flag_er {
    background-position: 0 -1312px;
}
.flag_es {
    background-position: 0 -1328px;
}
.flag_et {
    background-position: 0 -1344px;
}
.flag_fi {
    background-position: 0 -1360px;
}
.flag_fj {
    background-position: 0 -1376px;
}
.flag_fm {
    background-position: 0 -1392px;
}
.flag_fo {
    background-position: 0 -1408px;
}
.flag_fr {
    background-position: 0 -1424px;
}
.flag_bl,
.flag_cp,
.flag_mf,
.flag_yt {
    background-position: 0 -1424px;
}
.flag_ga {
    background-position: 0 -1440px;
}
.flag_gb {
    background-position: 0 -1456px;
}
.flag_sh {
    background-position: 0 -1456px;
}
.flag_gd {
    background-position: 0 -1472px;
}
.flag_ge {
    background-position: 0 -1488px;
}
.flag_gg {
    background-position: 0 -1504px;
}
.flag_gh {
    background-position: 0 -1520px;
}
.flag_gi {
    background-position: 0 -1536px;
}
.flag_gl {
    background-position: 0 -1552px;
}
.flag_gm {
    background-position: 0 -1568px;
}
.flag_gn {
    background-position: 0 -1584px;
}
.flag_gp {
    background-position: 0 -1600px;
}
.flag_gq {
    background-position: 0 -1616px;
}
.flag_gr {
    background-position: 0 -1632px;
}
.flag_gt {
    background-position: 0 -1648px;
}
.flag_gu {
    background-position: 0 -1664px;
}
.flag_gw {
    background-position: 0 -1680px;
}
.flag_gy {
    background-position: 0 -1696px;
}
.flag_hk {
    background-position: 0 -1712px;
}
.flag_hn {
    background-position: 0 -1728px;
}
.flag_hr {
    background-position: 0 -1744px;
}
.flag_ht {
    background-position: 0 -1760px;
}
.flag_hu {
    background-position: 0 -1776px;
}
.flag_id {
    background-position: 0 -1792px;
}
.flag_mc {
    background-position: 0 -1792px;
}
.flag_ie {
    background-position: 0 -1808px;
}
.flag_il {
    background-position: 0 -1824px;
}
.flag_im {
    background-position: 0 -1840px;
}
.flag_in {
    background-position: 0 -1856px;
}
.flag_iq {
    background-position: 0 -1872px;
}
.flag_ir {
    background-position: 0 -1888px;
}
.flag_is {
    background-position: 0 -1904px;
}
.flag_it {
    background-position: 0 -1920px;
}
.flag_je {
    background-position: 0 -1936px;
}
.flag_jm {
    background-position: 0 -1952px;
}
.flag_jo {
    background-position: 0 -1968px;
}
.flag_jp {
    background-position: 0 -1984px;
}
.flag_ke {
    background-position: 0 -2000px;
}
.flag_kg {
    background-position: 0 -2016px;
}
.flag_kh {
    background-position: 0 -2032px;
}
.flag_ki {
    background-position: 0 -2048px;
}
.flag_km {
    background-position: 0 -2064px;
}
.flag_kn {
    background-position: 0 -2080px;
}
.flag_kp {
    background-position: 0 -2096px;
}
.flag_kr {
    background-position: 0 -2112px;
}
.flag_kw {
    background-position: 0 -2128px;
}
.flag_ky {
    background-position: 0 -2144px;
}
.flag_kz {
    background-position: 0 -2160px;
}
.flag_la {
    background-position: 0 -2176px;
}
.flag_lb {
    background-position: 0 -2192px;
}
.flag_lc {
    background-position: 0 -2208px;
}
.flag_li {
    background-position: 0 -2224px;
}
.flag_lk {
    background-position: 0 -2240px;
}
.flag_lr {
    background-position: 0 -2256px;
}
.flag_ls {
    background-position: 0 -2272px;
}
.flag_lt {
    background-position: 0 -2288px;
}
.flag_lu {
    background-position: 0 -2304px;
}
.flag_lv {
    background-position: 0 -2320px;
}
.flag_ly {
    background-position: 0 -2336px;
}
.flag_ma {
    background-position: 0 -2352px;
}
.flag_md {
    background-position: 0 -2368px;
}
.flag_me {
    background-position: 0 -2384px;
}
.flag_mg {
    background-position: 0 -2400px;
}
.flag_mh {
    background-position: 0 -2416px;
}
.flag_mk {
    background-position: 0 -2432px;
}
.flag_ml {
    background-position: 0 -2448px;
}
.flag_mm {
    background-position: 0 -2464px;
}
.flag_mn {
    background-position: 0 -2480px;
}
.flag_mo {
    background-position: 0 -2496px;
}
.flag_mq {
    background-position: 0 -2512px;
}
.flag_mr {
    background-position: 0 -2528px;
}
.flag_ms {
    background-position: 0 -2544px;
}
.flag_mt {
    background-position: 0 -2560px;
}
.flag_mu {
    background-position: 0 -2576px;
}
.flag_mv {
    background-position: 0 -2592px;
}
.flag_mw {
    background-position: 0 -2608px;
}
.flag_mx {
    background-position: 0 -2624px;
}
.flag_my {
    background-position: 0 -2640px;
}
.flag_mz {
    background-position: 0 -2656px;
}
.flag_na {
    background-position: 0 -2672px;
}
.flag_nc {
    background-position: 0 -2688px;
}
.flag_ne {
    background-position: 0 -2704px;
}
.flag_ng {
    background-position: 0 -2720px;
}
.flag_ni {
    background-position: 0 -2736px;
}
.flag_nl {
    background-position: 0 -2752px;
}
.flag_bq {
    background-position: 0 -2752px;
}
.flag_no {
    background-position: 0 -2768px;
}
.flag_bv,
.flag_nq,
.flag_sj {
    background-position: 0 -2768px;
}
.flag_np {
    background-position: 0 -2784px;
}
.flag_nr {
    background-position: 0 -2800px;
}
.flag_nz {
    background-position: 0 -2816px;
}
.flag_om {
    background-position: 0 -2832px;
}
.flag_pa {
    background-position: 0 -2848px;
}
.flag_pe {
    background-position: 0 -2864px;
}
.flag_pf {
    background-position: 0 -2880px;
}
.flag_pg {
    background-position: 0 -2896px;
}
.flag_ph {
    background-position: 0 -2912px;
}
.flag_pk {
    background-position: 0 -2928px;
}
.flag_pl {
    background-position: 0 -2944px;
}
.flag_pr {
    background-position: 0 -2960px;
}
.flag_ps {
    background-position: 0 -2976px;
}
.flag_pt {
    background-position: 0 -2992px;
}
.flag_pw {
    background-position: 0 -3008px;
}
.flag_py {
    background-position: 0 -3024px;
}
.flag_qa {
    background-position: 0 -3040px;
}
.flag_re {
    background-position: 0 -3056px;
}
.flag_ro {
    background-position: 0 -3072px;
}
.flag_rs {
    background-position: 0 -3088px;
}
.flag_ru {
    background-position: 0 -3104px;
}
.flag_rw {
    background-position: 0 -3120px;
}
.flag_sa {
    background-position: 0 -3136px;
}
.flag_sb {
    background-position: 0 -3152px;
}
.flag_sc {
    background-position: 0 -3168px;
}
.flag_sd {
    background-position: 0 -3184px;
}
.flag_se {
    background-position: 0 -3200px;
}
.flag_sg {
    background-position: 0 -3216px;
}
.flag_si {
    background-position: 0 -3232px;
}
.flag_sk {
    background-position: 0 -3248px;
}
.flag_sl {
    background-position: 0 -3264px;
}
.flag_sm {
    background-position: 0 -3280px;
}
.flag_sn {
    background-position: 0 -3296px;
}
.flag_so {
    background-position: 0 -3312px;
}
.flag_sr {
    background-position: 0 -3328px;
}
.flag_st {
    background-position: 0 -3344px;
}
.flag_sv {
    background-position: 0 -3360px;
}
.flag_sy {
    background-position: 0 -3376px;
}
.flag_sz {
    background-position: 0 -3392px;
}
.flag_tc {
    background-position: 0 -3408px;
}
.flag_td {
    background-position: 0 -3424px;
}
.flag_tg {
    background-position: 0 -3440px;
}
.flag_th {
    background-position: 0 -3456px;
}
.flag_tj {
    background-position: 0 -3472px;
}
.flag_tl {
    background-position: 0 -3488px;
}
.flag_tm {
    background-position: 0 -3504px;
}
.flag_tn {
    background-position: 0 -3520px;
}
.flag_to {
    background-position: 0 -3536px;
}
.flag_tr {
    background-position: 0 -3552px;
}
.flag_tt {
    background-position: 0 -3568px;
}
.flag_tv {
    background-position: 0 -3584px;
}
.flag_tw {
    background-position: 0 -3600px;
}
.flag_tz {
    background-position: 0 -3616px;
}
.flag_ua {
    background-position: 0 -3632px;
}
.flag_ug {
    background-position: 0 -3648px;
}
.flag_us {
    background-position: 0 -3664px;
}
.flag_uy {
    background-position: 0 -3680px;
}
.flag_uz {
    background-position: 0 -3696px;
}
.flag_va {
    background-position: 0 -3712px;
}
.flag_vc {
    background-position: 0 -3728px;
}
.flag_ve {
    background-position: 0 -3744px;
}
.flag_vg {
    background-position: 0 -3760px;
}
.flag_vi {
    background-position: 0 -3776px;
}
.flag_vn {
    background-position: 0 -3792px;
}
.flag_vu {
    background-position: 0 -3808px;
}
.flag_ws {
    background-position: 0 -3824px;
}
.flag_ye {
    background-position: 0 -3840px;
}
.flag_za {
    background-position: 0 -3856px;
}
.flag_zm {
    background-position: 0 -3872px;
}
.flag_zw {
    background-position: 0 -3888px;
}
.flag_sx {
    background-position: 0 -3904px;
}
.flag_cw {
    background-position: 0 -3920px;
}
.flag_ss {
    background-position: 0 -3936px;
}
.flag_nu {
    background-position: 0 -3952px;
}
