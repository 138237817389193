@import '../../styles/variables.scss';

.dialogLayer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10000;
}
.layerShow {
    display: block;
}
.layerHide {
    display: none;
}
.dialogHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px 25px;
    border-bottom: 1px solid rgba(144, 160, 175, 0.15);
}
.dialogTitle {
    font-size: 1.8rem;
}
.dialogCloseBtn {
    display: block;
    width: 1.8rem;
    fill: $main-text-color;
    cursor: pointer;
    transform: rotate(45deg);
}
.dialogBody {
    font-size: 16px;
    text-align: center;
    color: #575d70;
    padding: 20px;
    margin-bottom: 20px;
    line-height: 2.4rem;
    background-color: #ffffff;
    border-radius: 0 0 5px 5px;
}
.dialogFooter {
    display: flex;
    flex-direction: column;
    padding: 0 3rem 50px 3rem;
    text-align: center;

    :global {
        .btn-base {
            text-transform: uppercase;
            padding: 1.3rem 3rem;
            margin-left: 0;
            margin-bottom: 10px;
            border-radius: 4px;
            border: 1px solid rgba(144, 160, 175, 0.15) !important;
            background: $color-white !important;
            color: $text-color-black !important;
            &:hover {
                background: $text-color-warning !important;
                color: $color-white !important;
                border-color: transparent !important;
            }

            &.btn-rounded {
                min-width: 18rem;
            }
        }
    }
}
.dialogOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.55);
    top: 0;
    left: 0;
    z-index: 10000;

    :global &.transition-enter {
        opacity: 0.01;
    }

    :global &.transition-enter-active {
        opacity: 1;
        transition: opacity 300ms;
    }

    :global &.transition-leave {
        opacity: 1;
    }

    :global &.transition-leave-active {
        opacity: 0.01;
        transition: opacity 200ms;
    }
}
.dialogContainer {
    width: 350px;
    position: fixed;
    left: 50%;
    top: 60%;
    background: #fff;
    transform: translate(-50%, -65%);
    z-index: 10100;
    border-radius: 4px;

    &:global.transition-enter {
        transform: translate(-50%, -100%);
        opacity: 0.01;
    }

    &:global.transition-enter-active {
        transform: translate(-50%, -65%);
        opacity: 1;
        transition: transform 300ms, opacity 200ms;
    }

    &:global.transition-leave {
        opacity: 1;
        transform: translate(-50%, -65%);
    }

    &:global.transition-leave-active {
        transform: translate(-50%, -100%);
        opacity: 0.01;
        transition: transform 200ms, opacity 100ms;
    }
}
