@import './../../../../styles/fonts';

.warningToastBox {
    max-width: 600px;
    .warningMessage {
        @include font-regular();
    }
    svg {
        fill: black;
    }
    .progressBox {
        position: absolute;
        bottom: 1px;
        left: 0;
        right: 0;
    }
    :global {
        .MuiLinearProgress-root {
            &:before {
                background-color: white;
            }
        }
        .MuiLinearProgress-bar {
            background-color: rgba(255, 255, 255, 0.7);
        }
    }
}
