@import '../../../../../styles/fonts';

.dragLabelContainer {
    position: absolute;
    top: -10px;
    opacity: 0;
    cursor: grab;
}
.dragLabel {
    padding: 7px 15px;
    border-radius: 5px;
    color: #ffffff;
    @include font-medium();
    font-size: 13px;
    display: inline-block;
    word-break: break-all;
}
.dragging {
    opacity: 1;
}
