@import "../../styles/variables";

.badge {
  position: absolute;
  height: 1.6rem;
  padding: 0 .5rem;
  background-color: $warning-color;
  color: #fff;
  border-radius: 4rem;
  font-size: 1.1rem;
  line-height: 1.6rem;
  text-align: center;

  &.transition {
    background-color: transparent;
  }

  &.size- {
    &middle {
      font-weight: 600;
      font-size: 1.4rem;
    }
  }
}
