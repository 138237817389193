@import '../../../../styles/variables.scss';

.list-header {
    margin-top: 20px;
    border-bottom: 1px solid rgba(144, 160, 175, 0.15);
    padding-bottom: 30px;
    span {
        margin-left: 20px;
    }
}
.grid-table {
    svg {
        font-size: 26px;
    }
}

.MuiTooltip-popper {
    .MuiTooltip-tooltip {
        font-family: 'Ezlo Sans';
        font-size: 10px;
    }
}

.meshbot-cell-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;

    &-info {
        color: $color-button-blue;
    }

    &.type {
        svg {
            fill: #afafaf;
            width: 14px;
            margin-right: 12px;
        }
    }

    svg {
        align-self: center;
        width: 20px;
        min-width: 20px;
    }
}
