@import '../../styles/variables.scss';

.confirmationModal {
    text-align: center;
    font-size: 16px;
    background-color: $color-white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    padding: 20px;
    border-radius: 24px;
    font-family: 'Ezlo Sans';

    :global {
        .MuiButtonBase-root {
            font-family: 'Ezlo Sans';
            height: 48px;
            width: 48%;
            text-transform: none;

            .MuiButton-label {
                font-size: 16px;
            }
        }
    }
}

.title {
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    margin: 0 0 16px 0;
    color: $text-color-black;
}

.text {
    text-align: center;
    font-size: 15px;
    padding-bottom: 15px;
}

.controls {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.confirmationModalVirtualContainer {
    background-color: $color-white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 455px;
    padding: 20px;
    border-radius: 24px;
    font-family: 'Ezlo Sans';
}

.closeIcon {
    width: 22px;
}

.deleteIcon {
    display: block;
    width: 170px;
    height: 170px;
    margin: 10px auto 0;
}

.modalBodyTitle {
    text-align: center;
    font-weight: 600;
    font-size: 30px;
    color: $text-color-black;
    margin: 0 0 15px 0;
}

.modalBodyText {
    text-align: center;
    font-size: 16px;
    color: $text-color-black;
    margin-bottom: 30px;
}

.modalBody {
    :global {
        .MuiButtonBase-root {
            &.MuiButton-containedPrimary {
                display: block;
                width: 95%;
                margin: 0 auto 10px;
                padding: 10px;
                background-color: $text-color-warning;
                &:hover {
                    background-color: $text-color-warning-3;
                }
            }
        }
    }
}
