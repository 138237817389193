@import '../../styles/variables';

.meshbotTypeCell {
    display: flex;
    align-items: center;
    span {
        text-transform: capitalize;
    }
    svg {
        fill: rgba(0, 0, 0, 0.24);
        width: 20px;
        margin-right: 12px;
    }
}

:global(.MuiDataGrid-row:hover) {
    :local {
        .meshbotTypeCell svg {
            fill: rgba(0, 0, 0, 0.45);
        }
    }
}
