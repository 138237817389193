@import '../../../../../styles/variables.scss';

.wrapper {
    .info_button {
        position: absolute;
        top: -15px;
        right: -7px;
        padding: 6px;
        background-color: transparent;
        z-index: 1;
        @media screen and (max-width: 1280px) {
            top: -10px;
        }

        :global {
            .MuiSvgIcon-root {
                font-size: 11px;
                z-index: 1;
                color: $info-btn-color;
            }
        }

        svg {
            width: 12px;
            height: 12px;

            path {
                fill: $text-color-grey;
            }
        }
    }

    .hide {
        opacity: 0;
        transition: opacity 2s linear;
    }

    .buttonTabTrue {
        width: 24px;
        top: 0;
        left: 0;
    }

    .buttonTabFalse {
        width: 24px;
        top: 0;
        right: 0;
    }
}

.box {
    white-space: pre-line;
}

.paperContainer {
    :global {
        .MuiBox-root {
            position: relative;
            background-color: $color-tooltip-background;
            max-width: 300px;
            color: $color-black;
            padding: 15px;
            font-size: 14px;
            border: 1px solid $color-black;
            border-radius: 3px;

            h4 {
                font-weight: bold;
                margin-top: 0;
                margin-bottom: 0px;
            }

            a {
                color: $color-blue-3;
                text-decoration: underline;
            }

            button {
                position: absolute;
                top: 0;
                right: 0;
                padding: 8px;

                svg {
                    color: $color-black;
                    font-size: 14px;
                    font-weight: bold;
                }
            }

            p {
                margin-top: 0px;

                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}

.zIndex1500 {
    z-index: 1500;
}
