.contentBox {
    h4 {
        margin: 0 10px 3px 0;
        &:first-letter {
            text-transform: uppercase;
        }
    }
    div {
        &:first-letter {
            text-transform: uppercase;
        }
    }
}
