@import '../../../../../../styles/fonts';

.muiDialog {
    :global {
        .MuiPaper-rounded {
            border-radius: 24px;
        }
        .MuiButton-root {
            margin-bottom: 20px;
        }
    }
}
.dialogContainer {
    padding: 20px 24px;
    position: relative;
    :global {
        .MuiSvgIcon-root {
            font-size: 32px;
            fill: #1c1e21;
        }
    }
}

.closeDialogIconBox {
    position: absolute;
    top: 7px;
    left: 8px;
}
.dialogTitle {
    font-size: 26px;
    text-align: center;
    padding-left: 32px;
    @include font-semibold();
}
