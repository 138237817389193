@import './variables';
@import './fonts.scss';

@mixin rounded-input() {
    width: 100%;
    background: #fff;
    border-radius: 4rem;
    border: 2px solid #e8e8e8;
    padding: 0.9rem 2rem;
    font-size: 1.4rem;
    margin: 1rem 0;
    outline: none;
    color: #333;

    &::-webkit-input-placeholder {
        color: #aaa;
    }

    &::-moz-placeholder {
        color: #aaa;
    }

    &:-ms-input-placeholder {
        color: #aaa;
    }

    &:-moz-placeholder {
        color: #aaa;
    }

    &:focus {
        border-color: $base-color-input-focus;
    }

    &.error {
        border-color: $danger-color-input-focus;
    }
}

@mixin focus($color: $base-color) {
    outline: initial;
    box-shadow: 0 0 10px $color;
}

@mixin vertical-align() {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin mask($color: rgba(0, 0, 0, 0.5)) {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $color;
}

@mixin inline-spinner($full-rotation-time: 1s) {
    display: inline-block;
    animation: loading $full-rotation-time infinite linear;
}

@mixin clearfix() {
    &:after {
        content: '.';
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
}

@mixin ellipsis() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin rounded-image-container() {
    width: 22.5rem;
    height: 22.5rem;
    border-radius: 50%;
}

@mixin ellipsis() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@keyframes loading {
    to {
        transform: rotate(360deg);
    }
}

@keyframes pulsation {
    0% {
        opacity: 1;
    }
    12.5% {
        opacity: 0.75;
    }
    25% {
        opacity: 0.5;
    }
    37.5% {
        opacity: 0.25;
    }
    50% {
        opacity: 0;
    }
    62.5% {
        opacity: 0.25;
    }
    75% {
        opacity: 0.5;
    }
    87.5% {
        opacity: 0.75;
    }
    100% {
        opacity: 1;
    }
}

// Calcul em size depending of the context
// Use em(fontSizeDoIWantInEm, fontContextInEM)
// padding: 0 em(3.5, 2.2) 0 em(5, 2.2);
@function em($fontSize, $context) {
    @return #{$fontSize/$context}em;
}

@mixin respond-to($media) {
    @if $media == desktop-xxx {
        @media only screen and (max-width: $break-d-xxx) {
            @content;
        }
    } @else if $media == desktop-xx {
        @media only screen and (max-width: $break-d-xx) {
            @content;
        }
    } @else if $media == desktop-x {
        @media only screen and (max-width: $break-d-x) {
            @content;
        }
    } @else if $media == desktop-l {
        @media only screen and (max-width: $break-d-l) {
            @content;
        }
    } @else if $media == desktop-m {
        @media only screen and (max-width: $break-d-m) {
            @content;
        }
    } @else if $media == desktop-s {
        @media only screen and (max-width: $break-d-s) {
            @content;
        }
    } @else if $media == tablet-l {
        @media only screen and (max-width: $break-t-l) {
            @content;
        }
    } @else if $media == tablet-m {
        @media only screen and (max-width: $break-t-m) {
            @content;
        }
    } @else if $media == tablet-s {
        @media only screen and (max-width: $break-t-s) {
            @content;
        }
    } @else if $media == mobile-l {
        @media only screen and (max-width: $break-m-l) {
            @content;
        }
    } @else if $media == mobile-m {
        @media only screen and (max-width: $break-m-m) {
            @content;
        }
    } @else if $media == mobile-s {
        @media only screen and (max-width: $break-m-s) {
            @content;
        }
    } @else if $media == mobile-xs {
        @media only screen and (max-width: $break-m-xs) {
            @content;
        }
    }
}

@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

@mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    }

    @include optional-at-root(':-moz-placeholder') {
        @content;
    }

    @include optional-at-root('::-moz-placeholder') {
        @content;
    }

    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    }
}

@mixin flexBox($justify: inherit, $align: inherit) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
}

@mixin flexFlow($wrap: nowrap, $direction: row) {
    flex-wrap: $wrap;
    flex-direction: $direction;
}

@mixin flex($grow: 0, $shrink: 1, $width: 0) {
    flex-grow: $grow;
    flex-shrink: $shrink;
    flex-basis: $width;
}

@mixin flexDirectionAlign($direction: row, $align: inherit) {
    display: flex;
    flex-direction: $direction;
    align-items: $align;
}

@mixin inputScrollbar() {
    scrollbar-width: thin;
    &::-webkit-scrollbar-track {
        //-webkit-box-shadow: inset 0 0 6px rgba(168, 178, 208, 0.37);
        //border-radius: 4px;
        background-color: #f0f0f0;
    }
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: $scrollbar-thumb-background;
    }
}

@mixin listScrollbar() {
    scrollbar-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    scrollbar-width: thin;
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: transparent;
    }

    &:hover {
        scrollbar-width: thin;
        scrollbar-color: $scrollbar-thumb-background rgba(0, 0, 0, 0);
    }
    &:hover::-webkit-scrollbar {
        width: 6px;
    }
    &:hover::-webkit-scrollbar-thumb {
        border-radius: 3px;
        min-height: 48px;
        background-color: $scrollbar-thumb-background;
    }
}

@mixin tableScrollbar() {
    scrollbar-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    scrollbar-width: auto;
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: transparent;
    }

    &:hover {
        scrollbar-width: auto;
        scrollbar-color: $scrollbar-thumb-background rgba(0, 0, 0, 0);
    }
    &:hover::-webkit-scrollbar {
        width: 12px;
    }
    &:hover::-webkit-scrollbar-thumb {
        border-radius: 5px;
        min-height: 48px;
        background-color: $scrollbar-thumb-background;
    }
}

@keyframes skeleton-loading {
    0% {
        opacity: 1;
    }
    12.5% {
        opacity: 0.875;
    }
    25% {
        opacity: 0.75;
    }
    37.5% {
        opacity: 0.625;
    }
    50% {
        opacity: 0.5;
    }
    62.5% {
        opacity: 0.625;
    }
    75% {
        opacity: 0.75;
    }
    87.5% {
        opacity: 0.875;
    }
    100% {
        opacity: 1;
    }
}

@keyframes ellipsis-animation {
    to {
        width: 1.25em;
    }
}

@-webkit-keyframes ellipsis-animation {
    to {
        width: 1.25em;
    }
}

@mixin itemsGap {
    gap: 8px;
    margin-bottom: 5px;
}

@mixin itemsFlow($width: 44px) {
    width: $width;
    height: 44px;
    vertical-align: middle;
    font-size: 16px;
    border-radius: 2px;
    background: $month-item-bg;
    color: $text-color-grey;
    font-family: 'Ezlo Sans';
    transition: 0.3s;
    &:hover {
        background: $radio-button-bg-hover;
        color: $color-white;
    }
}

@mixin itemsActiveFlow {
    background: $radio-button-bg-active;
    color: $color-white;
}

@mixin modalContainer {
    position: relative;
    background-color: $color-white;
    border-radius: 4px;
    width: 436px;
    min-height: 400px;
    padding: 15px;
    height: 400px;
    overflow-y: auto;
}

@mixin selectStyle($minWidth: 120px, $width: auto) {
    margin-right: 24px;
    min-width: $minWidth;
    margin-bottom: 5px;
    width: $width;
}

@mixin timeInputWithContent($content) {
    position: absolute;
    bottom: 6px;
    right: 24px;
    font-size: 13px;
    @include font-medium();
    color: $text-color-black;
    content: $content;
}

@mixin meshbotBoxShadow {
    box-shadow: 0px 2px 8px 2px rgba($color-black, 0.08);
}

@mixin heightOfSelect {
    max-height: 400px !important;
}

@mixin autocompleteOption {
    @include font-regular;
    font-size: 14px;
    color: $text-color-black;
}

@mixin meshbotAdditionButton {
    background-color: $color-grey;
    color: $text-color-black;
    font-size: 14px;
    &:hover {
        background-color: $color-grey;
    }
}

@mixin meshbotButton {
    background-color: $color-blue-3;
    color: $color-white;
    font-size: 14px;
    padding: 5px;
    height: auto;
    &:hover {
        background-color: $radio-button-bg-active;
    }
}

@mixin highlightAccountTypeColumn($childNum) {
    tr {
        :nth-child(#{$childNum}) {
            color: $color-blue-3;
            background-color: $color-blue-light;
        }
    }
}

@mixin transparentBackgroundColorMixin($alpha, $color) {
    background-color: rgba(red($color), green($color), blue($color), $alpha);
}

@mixin fontStyle($size, $weight) {
    font-size: $size;
    color: $text-color-black;
    font-style: normal;
    font-weight: $weight;
    font-family: 'Ezlo Sans';
}

@mixin labelsList() {
    width: calc(100% - #{$labels-dropdown-menu});
}
@mixin textEllipsis($rows: 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: $rows;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    word-break: break-all;
}

@mixin spin-animation() {
    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}
