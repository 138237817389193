@import "../../styles/variables";

.number-input {
  display: inline-block;
  position: relative;

  &-plus,
  &-minus {
    position: absolute;
    top: 0;
    right: 1rem;
    width: 1rem;
    height: 50%;
    cursor: pointer;
    user-select: none;

    &:hover {
      svg {
        fill: $button-color;
      }
    }

    &:active {
      svg {
        fill: $button-pressed-color;
      }
    }

    svg {
      fill: $additional-text-color;
      vertical-align: top;
    }
  }

  &-minus {
    top: auto;
    bottom: 0;

    svg {
      margin-top: .2rem;
    }
  }

  &-plus {
    svg {
      margin-top: 1rem;
    }
  }

  input {
    width: 100%;
    margin: 0;
    font-size: 1.6rem;
    padding: 1rem 2rem;
    line-height: normal;
    border: 1px solid #CBDAE6;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: inset 0 2px 2px 0 rgba(168, 178, 208, 0.64);
    outline: none;
    color: $additional-text-color;

    &[readonly],
    &[readonly]:focus,
    &[disabled] {
      cursor: not-allowed;
      border-color: $disabled-color;
    }
  }
}
