@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/fonts';

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.preloader.ezlo-page-wrapper {
    .content-wrapper {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .loader {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-bottom: 60px;

            &__icon {
                -webkit-animation: spin 2s linear infinite;
                -moz-animation: spin 2s linear infinite;
                animation: spin 2s linear infinite;
            }

            &__title {
                @include font-medium();
                text-align: left;
                min-width: 200px;
                padding-left: 42px;
                font-size: 32px;
                line-height: 1.2;
                display: flex;
                flex-wrap: wrap;

                .animated-dots {
                    text-align: start !important;
                }

                .animated-dots::after {
                    overflow: hidden;
                    display: inline-block;
                    vertical-align: bottom;
                    -webkit-animation: ellipsis-animation steps(4, end) 900ms infinite;
                    animation: ellipsis-animation steps(4, end) 900ms infinite;
                    content: '\2026'; /* ascii code for the ellipsis character */
                    width: 0;
                }
            }
        }
    }
}
