@import '../../../../../../../styles/variables';

.searchByDateContainer {
    margin: 0 15px 0 0;

    input::placeholder {
        color: $text-color-description;
        opacity: 1;
        font-size: 16px;
    }

    :global {
        .MuiFormControl-root {
            margin-bottom: 0;
            max-width: 140px;

            &:first-child {
                margin-right: 15px;
            }
        }

        .MuiInputBase-root {
            font-weight: 400;
            font-size: 16px;
        }

        .MuiFormHelperText-root.Mui-error {
            position: absolute;
            bottom: -15px;
            font-size: 10px;
        }
    }
}
