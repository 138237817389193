@import '../../styles/mixins';
@import '../../styles/variables.scss';

.button {
    display: inline-block;
    width: fit-content;
    height: 28px;
    margin-top: 10px;
    margin-right: 25px;

    input[type='checkbox'] {
        display: none;
    }
}

.checkbox_label {
    display: block;
    font-size: 12px;
    height: 28px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.3s;
    color: $text-color-black;
    background: $color-grey;
    border-radius: 2px;
    padding: 7px 10px;

    &:hover {
        cursor: pointer;
        background: darken($color-grey, 10%);
    }
}

.checkbox_activeGroup {
    border-radius: 0;
    background-color: $color-white;
}

.checkbox_active {
    color: $color-white;
    background: $color-inValid;

    &:hover {
        background: darken($color-inValid, 10%);
    }
}

.wrapper_buttonGroup {
    margin-top: 0;
    margin-right: 0;
}
