@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';

.text-field {
    @include font-regular();
    font-size: 18px;
    line-height: 22px;

    input {
        & {
            filter: none;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            // prevent input field background filling for autofill case by default color
            // works good, but need to investigate deeper
            -webkit-box-shadow: 0 0 0 20px white inset !important;
        }
        &:-webkit-autofill:first-line {
            // works good, but need to investigate deeper
            @include font-regular();
            font-size: 18px;
            line-height: 22px;
        }
    }
}
