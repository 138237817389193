@import '../../styles/variables';

$border-color: #cbdae6;
$background-color: #fff;
$background-color-hover: #fbfbfb;
$menu-item-font-color: #3f3f3f;
$content-container-max-height: 23.5rem;
$border-size: 0.1rem;

.dropdown {
    display: inline-block;
    text-align: left;
    position: relative;

    &.read-only {
        .dropdown-toggle {
            cursor: default;
        }
    }

    &.animation {
        .dropdown-content {
            overflow: hidden;
        }
    }

    &.open {
        .dropdown-toggle {
            background: $background-color;
            border-color: $border-color;
            border-bottom-color: transparent;
            border-radius: 0.4rem 0.4rem 0 0;
            z-index: 2;
        }

        .dropdown-content {
            visibility: visible;
            opacity: 1;
            border-radius: 0 0.4rem 0.4rem 0.4rem;
            z-index: 1;

            .undefined-menu-item {
                display: flex;
                align-items: center;
                min-height: 40px;
                cursor: pointer;
                transition: 0.3s;

                &:hover {
                    background-color: #4f5287;

                    > span {
                        color: #fff;
                    }
                }
            }
        }

        &.drop-up {
            .dropdown-toggle {
                border-top-color: transparent;
                border-bottom-color: $border-color;
                border-radius: 0 0 0.4rem 0.4rem;
            }

            .dropdown-content {
                border-radius: 0.4rem 0.4rem 0.4rem 0;
                margin-top: $border-size;
            }
        }
    }

    &.drop-up {
        .dropdown-content {
            transform: translateY(-100%);
            top: $border-size + 0.1rem;
        }
    }

    &-toggle {
        position: relative;
        padding: 0 3.3rem 0 2.2rem;
        border: $border-size solid transparent;
        line-height: 3.4rem;
        color: #2fabea;
        cursor: pointer;
        transition: all 150ms ease-in;
        user-select: none;
        outline: none;

        &.not-allow {
            &:hover {
                cursor: not-allowed;
            }
        }
    }

    &-arrow {
        width: 1rem;
        top: 1.4rem;
        right: 1.2rem;
        fill: #2fabea;
        position: absolute;
    }

    &-content {
        min-width: 100%;
        position: absolute;
        background: $background-color;
        padding: 0 0 0.8rem 0;
        border-radius: 0.4rem;
        border: $border-size solid $border-color;
        margin: -$border-size 0 0 0;
        opacity: 0;
        max-height: 0;
        overflow-x: hidden;
        visibility: hidden;
    }

    &-menu-item {
        padding: 0 2.2rem;
        line-height: 3.3rem;
        color: $menu-item-font-color;
        cursor: pointer;
        white-space: nowrap;

        &.group {
            color: $secondary-text-color;
            cursor: default;

            &:hover {
                background: none;
            }
        }

        &:hover {
            background: $background-color-hover;
        }
    }

    &.select {
        &.open {
            .dropdown-toggle {
                border-radius: 0.4rem 0.4rem 0 0;
                border-bottom-color: #fff;
                z-index: 2;
            }

            .dropdown-content {
                border-radius: 0 0.4rem 0.4rem 0.4rem;
                border-top: $border-size solid $border-color;
                margin-top: -$border-size;
                z-index: 1;
            }

            &.drop-up {
                .dropdown-toggle {
                    border-radius: 0 0 0.4rem 0.4rem;
                    border-top-color: #fff;
                    border-bottom-color: $border-color;
                }

                .dropdown-content {
                }
            }
        }

        .dropdown-toggle {
            position: relative;
            border-radius: 0.4rem;
            background-color: $background-color;
            box-shadow: inset 0 2px 2px 0 rgba(168, 178, 208, 0.64);
            font-size: $field-font-size;
            line-height: 3.8rem;
            padding: 0 5rem 0 2rem;
            border: $border-size solid $border-color;
            color: $additional-text-color;
            transition: none;

            .dropdown-arrow {
                fill: #777f97;
                top: 1.8rem;
                right: 2rem;
            }
        }

        .dropdown-content {
            border-radius: 0.4rem;
            padding: 0;

            .dropdown-arrow {
                fill: #777f97;
                top: 1.8rem;
                right: 1.8rem;
            }

            .dropdown-menu-item {
                line-height: 4.6rem;
                color: $menu-item-font-color;
                font-size: 1.4rem;
            }
        }
    }
}
