// Buttons styles
//---------------
@import "./variables";
@import "./fonts";

.btn-base {
  position: relative;
  background: $button-color;
  color: $text-color-grey;
  cursor: pointer;
  outline: none;
  border: 2px solid $button-color;
  text-decoration: none;
  padding: 1.1rem 3rem;
  letter-spacing: .1rem;
  font-size: 1.8rem;
  transition: color .25s, background .25s, border .25s;
  border-radius: 4rem;
  text-align: center;
  line-height: 1;

  &:hover, &:focus {
    background: $button-hover-color;
    border-color: $button-hover-color;
    color: $text-color-grey;
  }

  &:active {
    background: $button-pressed-color;
    border-color: $button-pressed-color;
    color: $text-color-grey;
  }

  &.inverse {
    background: none;
    color: $button-color;
    border-color: $button-color;

    &:hover, &:focus {
      background: $button-hover-color;
      border-color: $button-hover-color;
      color: $text-color-grey;
    }

    &:active {
      background: $button-pressed-color;
      border-color: $button-pressed-color;
      color: $text-color-grey;
    }

    &.disabled, &[disabled], &.disabled:hover, &[disabled]:hover {
      background: $disabled-color;
      cursor: not-allowed;

      background: none;
      border-color: $disabled-color;
      color: $disabled-color;
    }
  }

  &.disabled, &[disabled], &.disabled:hover, &[disabled]:hover {
    background: $disabled-color;
    border-color: $disabled-color;
    cursor: not-allowed;
  }

  &.small {
    padding: .8rem 2.5rem;
    font-size: 1.4rem;
    font-weight: 600;
    border-width: 1px;
  }
}

.btn-danger {
  background: $warning-color !important;
  border-color: $warning-color !important;
  color: $text-color-grey;

  &:hover, &:focus {
    background: $button-hover-color !important;
    border-color: $button-hover-color !important;
  }

  &:active {
    background: darken($warning-color, 10%) !important;
    border-color: darken($warning-color, 10%) !important;
  }

  &.inverse {
    background: $text-color-grey !important;
    color: $warning-color;

    &:hover, &:focus {
      background: $warning-color !important;
      border-color: $warning-color !important;
      color: $text-color-grey;
    }

    &:active {
      background: darken($warning-color, 10%) !important;
      border-color: darken($warning-color, 10%) !important;
      color: $text-color-grey;
    }
  }

  &[disabled], &[disabled]:hover {
    background: $disabled-color;
    border-color: $disabled-color;
    cursor: not-allowed;
  }
}

.btn-success {
  background: $success-color !important;
  border-color: $success-color !important;
  color: $text-color-grey;

  &:hover, &:focus {
    background: lighten($success-color, 10%) !important;
    border-color: lighten($success-color, 10%) !important;
  }

  &:active {
    background: darken($success-color, 10%) !important;
    border-color: darken($success-color, 10%) !important;
  }

  &.inverse {
    background: $text-color-grey !important;
    color: $success-color;

    &:hover, &:focus {
      background: $success-color !important;
      border-color: $success-color !important;
      color: $text-color-grey;
    }

    &:active {
      background: darken($success-color, 10%) !important;
      border-color: darken($success-color, 10%) !important;
      color: $text-color-grey;
    }
  }

  &[disabled], &[disabled]:hover {
    background: $disabled-color;
    border-color: $disabled-color;
    cursor: not-allowed;
  }
}

.btn-transparent {
  border-radius: 4rem;
  background: none;
  border: 2px solid transparent;
  outline: none;
  color: $button-color;

  &:hover,
  &:focus {
    background: $button-hover-color;
    border-color: $button-hover-color;
  }

  &:active {
    background: $button-pressed-color;
    border-color: $button-pressed-color;
  }

  &[disabled], &[disabled]:hover {
    background: none;
    border-color: transparent;
    color: #aaa;
    cursor: not-allowed;
  }
}

.btn-sidebar {
  border-radius: 4rem;
  background: none;
  border: 2px solid $sidebar-button-color;
  color: $sidebar-button-color;
  font-size: 1.8rem;

  &:hover,
  &:focus {
    border-color: $sidebar-button-color;
    background-color: $sidebar-button-hover-color;
    color: $sidebar-link-color;
  }

  &[disabled], &[disabled]:hover {
    background: none;
    border-color: $disabled-color;
    color: $disabled-color;
  }
}

.btn-link {
  background: none;
  border: none;
  border-radius: 0;
  color: $button-color;
  padding: 0;
  margin: 0 2rem;

  svg {
    fill: $button-color;
  }

  &:hover {
    background: none;
    color: $button-hover-color;

    svg {
      fill: $button-hover-color;
    }
  }

  &:active,
  &:focus {
    background: none;
    color: $button-pressed-color;

    svg {
      fill: $button-pressed-color;
    }
  }

  &[disabled], &[disabled]:hover {
    background: none;
    color: $disabled-color;

    svg {
      fill: $disabled-color;
    }
  }
}

