@import "../../styles/variables";
@import "../../styles/fonts";

.radio-button {
  //display: inline-block;
  //margin: 0;
  //cursor: pointer;

  &.checked {
    .radio-button-checkmark {
      opacity: 1;
      transition: all 250ms;
    }

    label {
      color: $base-color;
    }
  }

  &.content-left {
    .radio-button-container {
      margin-left: 1rem;
      margin-right: 0;
    }
  }

  &.disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 2rem;
    height: 2rem;
    border: 1px solid #CBDAE6;
    border-radius: 100%;
    margin-right: 1rem;
  }

  &-checkmark {
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: .4rem;
    left: .4rem;
    border-radius: 100%;
    background-color: #686DCE;
    opacity: 0;
  }

  &-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
  }

  //label {
  //  display: inline-block;
  //  vertical-align: middle;
  //  font-size: 1.6rem;
  //  line-height: normal;
  //  color: #3f3f3f;
  //  margin-left: 2rem;
  //  cursor: pointer;
  //  @include font-regular();
  //}
}
