@import '../../styles/variables.scss';

.meshbotDeleteWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    svg {
        font-size: 22px;
    }
    .disabled {
        svg {
            color: $color-button-grey-1;
        }
    }
}
