@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/fonts";

.left-panel {
  position: relative;
  color: $text-color-grey;
  min-width: 300px;
  min-height: 100%;
  background-color: $sidebar-bg-color;
  z-index: 1;
  display: flex;
  flex-direction: column;

  .left-panel-wrapper {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 15px;
      background-color: rgba(209, 215, 232, .5);
    }
  }
}
