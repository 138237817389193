@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';

.materialReactTableContainer {
    &:global(.MuiPaper-root) {
        box-shadow: none;
    }
    :global {
        .MuiTableCell-root {
            font-size: 16px;
            @include font-normal();
        }
        .MuiTableRow-head {
            font-size: 16px;
            box-shadow: none;
            .MuiInputBase-root {
                font-size: 14px;
                svg {
                    font-size: 18px;
                }
            }
            .Mui-TableHeadCell-Content-Actions {
                svg {
                    font-size: 26px;
                }
            }
            .MuiTableCell-root {
                padding-bottom: 8px;
                padding-top: 8px;
                font-weight: 500;
                vertical-align: middle;
            }
            .Mui-TableHeadCell-Content-Actions {
                margin-right: 8px;
                button {
                    width: 35px;
                    height: 35px;
                    padding: 3px;
                }
            }

            .MuiTableSortLabel-root {
                width: 17px;
                margin: 0 2px 0 4px;
                svg {
                    font-size: 16px;
                }
            }
        }

        .MuiToolbar-gutters {
            svg {
                font-size: 18px;
            }
        }
        .MuiTablePagination-actions {
            svg {
                font-size: 22px;
            }
        }
        .MuiTablePagination-selectIcon {
            top: calc(50% - 9px);
        }
        .MuiDivider-root {
            border: 1px solid #e0e0e0;
        }
        .MuiPaper-root {
            &.MuiAlert-root {
                font-size: 14px;
                @include font-light();
            }
        }
        .MuiTableContainer-root {
            @include listScrollbar();
        }
    }
}
