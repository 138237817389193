@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';
@import '../../../../../../styles/fonts';

.menuItem {
    min-width: 142px;
    &:global(.MuiButtonBase-root) {
        border-bottom: 1px solid #90a0af26;
    }

    &:last-child {
        border-bottom: none;
    }
    .menuItemText {
        color: $color-primary-theme;
        @include font-medium();
        margin: 0 10px;
    }

    svg {
        width: 11px;
        height: 11px;
        margin: 6px;
        path {
            fill: $color-primary-theme !important;
        }
    }
}
