@import 'src/styles/variables';

.spinner {
    width: 40px;
    height: 40px;
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    align-self: center;
    margin: 0 !important;

    path {
        stroke: $color-online;
        stroke-width: 12;
    }
}
.playArrow {
    margin: 8px;
}

.primary {
    color: var(--primary-color-theme);
}
.error {
    color: var(--dialog-dismiss-button-color);
}

.inherit {
    color: $text-color-grey;
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.meshbotRunWrapper {
    display: flex;
    justify-content: center;
    width: 100%;

    :global {
        .MuiIconButton-root {
            padding: 0;
        }
    }
}
