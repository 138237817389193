@import 'src/styles/variables';
@import 'src/styles/mixins';

.labelWrapper {
    border-radius: 10px;
    width: fit-content;
    max-width: 240px;
    padding: 6px;
    font-size: 12px;
    font-weight: 500;
    color: $color-white;
    margin: 0 4px 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-width: 26px;
}

.labelsListWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2px;
    @include labelsList;

    .labelName {
        @include ellipsis();
        display: inline-block;
        margin-left: 5px;

        &:hover {
            & + .tooltipName {
                display: block;
            }
        }
    }

    .tooltipName {
        display: none;
        position: absolute;
        top: 30px;
        left: 30px;
        background-color: $info-btn-color;
        color: #000000;
        z-index: 2;
        padding: 5px;
        border-radius: 5px;
        font-size: 10px;
        max-width: 350px;
        word-wrap: break-word;
    }
}

.deleteBtn {
    display: inline-block;
    cursor: pointer;

    svg {
        width: 8px;
        height: 8px;
    }
}

.deleteBtnWhite {
    svg {
        path {
            fill: $color-white;
        }
    }
}

.deleteBtnDark {
    svg {
        path {
            fill: $text-color-black;
        }
    }
}
