@import '../../../../../../../styles/variables';

.searchByDescriptionContainer {
    position: relative;
    margin: 0 15px 0 0;

    svg {
        position: absolute;
        right: 5px;
        top: 5px;

        & + div {
            margin: 0 0 0 0;
        }
    }

    input {
        padding: 5px 20px 5px 0;
    }

    input::placeholder {
        color: $text-color-description;
        opacity: 1;
        font-size: 16px;
    }
}
