@import '../fonts/fonts';

@mixin font-thin() {
    font-family: Ezlo Sans;
    font-weight: 100;
}

@mixin font-light() {
    font-family: Ezlo Sans;
    font-weight: 300;
}

@mixin font-normal() {
    font-family: Ezlo Sans;
    font-weight: 400;
}

@mixin font-regular() {
    font-family: Ezlo Sans;
    font-weight: 400;
}

@mixin font-medium() {
    font-family: Ezlo Sans;
    font-weight: 500;
}

@mixin font-semibold() {
    font-family: Ezlo Sans;
    font-weight: 600;
}

@mixin font-bold() {
    font-family: Ezlo Sans;
    font-weight: 700;
}

@mixin font-black() {
    font-family: Ezlo Sans;
    font-weight: 900;
}
