@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.templateCapability {
    border-radius: 15px;
    margin-bottom: 25px;
    @media (max-width: 760px) {
        position: relative;
    }

    & > div:first-child {
        width: 100%;
    }

    .row {
        background: $color-ocean-4;
        border-radius: 15px;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;

        .btnWrapper {
            display: flex;
            align-items: center;
            @media (max-width: 420px) {
                position: absolute;
                bottom: 15px;
                right: 10px;
            }
        }
    }

    .templateCapability__group {
        @include flexBox();
    }

    .templateCapability__select {
        @include flex(0, 1, 220px);
        @media (max-width: 760px) {
            @include flex(1, 1, 100%);
            &:first-child {
                margin: 0 20px 0 0;
            }
        }

        &.required .templateCapability__label:after {
            content: '*';
            color: $danger-color;
        }

        &.protocol {
            margin: 0 0 0 25px;
            @media (max-width: 760px) {
                margin: 0 0 0 0;
            }
        }
    }

    .templateCapability__label {
        font-size: 14px;
        color: $color-black;

        & + div {
            margin: 8px 0 0 0;
        }
    }

    .templateCapability__actions {
        margin: 20px 0 0 0;
        @media (max-width: 790px) {
            .MuiFormControl-root {
                width: 100%;
            }
        }

        .integer {
            margin: 0 30px 0 0;
            @media (max-width: 790px) {
                margin: 0;
                width: 100%;
            }

            p {
                margin-bottom: 0;
            }

            &.error {
                :global {
                    .MuiInput-underline:before,
                    .MuiInput-underline:after {
                        border-bottom: 2px solid red;
                    }
                }
            }
        }

        .string {
            margin: 0 30px 0 0;
            width: 400px;

            p {
                margin-bottom: 0;
            }
        }

        .string,
        .scale {
            @media (max-width: 790px) {
                width: 100%;
            }

            div {
                width: 100%;
            }
        }

        .fieldContainer {
            display: flex;
            align-items: center;
        }

        .row {
            padding: 0;
            align-items: center;
            margin: 0 0 0 300px;
        }
    }

    .templateCapability__buttonDelete {
        svg {
            width: 18px;
            height: 18px;
        }
    }

    .templateCapability__actions_item {
        border-bottom: 1px solid $color-black;
        padding-bottom: 25px;
        margin-bottom: 25px;

        .requestFields__label {
            font-size: 14px;
            color: $color-black;
        }

        .requestFields__value {
            font-size: 14px;
            line-height: 19px;
            border-bottom: 2px solid rgba(144, 160, 175, 0.5);
            padding: 9px 0 8px 0;
            margin-bottom: 0;
        }

        .requestFields__headersItem {
            @include flexBox();
            margin-bottom: 15px;

            label {
                z-index: 0;
            }
        }

        .requestFields__headersItem_input {
            width: 100%;

            input {
                padding: 10px 0;
                z-index: 9;
            }

            label {
                z-index: 0;
            }
        }

        .requestFields__buttonDelete {
            svg {
                width: 18px;
                height: 18px;
            }
        }

        .requestFields__buttonAdd {
            font-size: 11px;
        }

        :global {
            .MuiSelect-root.MuiSelect-select {
                min-height: 18px;
                font-size: 14px;
                line-height: 18px;
                padding: 10px 22px 10px 0;
            }

            .MuiFormHelperText-root {
                display: none;
            }

            .MuiInputBase-root {
                margin-top: 0;
            }

            .MuiInputLabel-formControl {
                transform: translate(0, 8px) scale(1);
            }

            .MuiInputLabel-shrink {
                transform: translate(0, -6px) scale(0.75);
            }

            .MuiFormControl-root {
                .MuiFormLabel-root.Mui-focused {
                    font-size: 14px;
                }
            }
        }
    }
}

.templateCapability__actions_requests {
    display: flex;
    background-color: $color-light-blue;
    border-radius: 15px;
    padding: 20px;
    @media (max-width: 560px) {
        flex-wrap: wrap;
    }

    .actions_name {
        width: 240px;

        p {
            font-size: 18px;
        }

        @media (max-width: 760px) {
            width: 100px;
        }
        @media (max-width: 560px) {
            width: 100%;
        }
    }
}

.requestsContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.mainFunctionBlock {
    background-color: #3e46ae20;
    padding: 10px;
    border-radius: 4px;

    .mainFunctionBlockRow {
        .content_type_wrapper {
            display: flex;
            flex-direction: column;

            .templateCapability__actions_headers {
                margin: 22px 0 0 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                flex-wrap: wrap;
                @media (max-width: 760px) {
                    margin: 0 0 0 0;
                }

                button {
                    max-width: 160px;
                }

                .headersValue {
                    min-width: 400px;
                    @media (max-width: 520px) {
                        min-width: 200px;
                    }
                }
            }
        }

        .labelBlock {
            display: flex;

            span {
                font-size: 14px;
            }
        }

        .dataField {
            width: 100%;
        }
    }
}

.pattern {
    max-width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    order: 2;
    @media (max-width: 760px) {
        flex-wrap: wrap;
    }

    .luaTestButton {
        background-color: $color-primary-button;
        color: $color-white;
        width: 200px;
        height: 40px;
        margin: 0 0 0 20px;
        @media (max-width: 760px) {
            margin: 20px 0 0 0;
        }

        &:hover {
            background-color: $color-primary-button;
        }
    }

    .patternField {
        min-width: 400px;
        @media (max-width: 520px) {
            min-width: auto;
        }
    }
}

.templateCapability__actions_method {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 10px 0 0;
    width: 150px;
    @media (max-width: 560px) {
        width: 100%;
    }

    div {
        z-index: 9;
    }

    .requestFields__label {
        font-size: 14px;
        color: $color-black;
    }

    :global {
        .MuiFormControl-root {
            margin-bottom: 0 !important;
            width: 100%;
        }
    }
}

.requestsWrapper {
    margin: 0 0 20px 0;
}

.rowSeparateFieldsWrapper {
    background-color: $color-light-blue;
    border-radius: 15px;
    margin: 20px 0 0 0;
    display: flex;
    flex-direction: column;

    & > div {
        padding: 20px 20px 20px 260px;

        &.max {
            padding: 0 20px 20px 260px;
            @media (max-width: 620px) {
                padding: 0 20px 20px 20px;
            }
        }

        @media (max-width: 620px) {
            padding: 20px 20px 20px 20px;
        }
    }
}

.mqttTopic,
.onState,
.min {
    order: 1;
}

.offState,
.max,
.auto {
    order: 2;
}

.on,
.cool {
    order: 3;
}

.heat {
    order: 4;
}

.off {
    order: 5;
}

.fieldsObjTypeWrapper {
    order: 3;

    & > div {
        display: flex;
        flex-direction: column;
    }
}

.stateComparatorTitle {
    font-weight: bold;
    font-size: 16px;
    color: $color-black;
    margin-bottom: 0;
}

.requestFields__headersItem {
    display: flex;
    flex-wrap: wrap;

    .btnWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}

.authorizationInput {
    width: 113px;
    margin: 15px 15px 0 0 !important;
}
