@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/fonts';

$items-container-max-height: 412px;

.ezlos_dropdown {
    display: block;
    width: 160px;
    height: 50px;
    text-align: left;
    background: $sidebar-bg-color;
    border: 1px solid lightgrey;
    border-radius: 10px;

    :global {
        .dropdown-toggle {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 16px;
        }
    }

    &.open {
        .dropdown_toggle {
            background: transparent;
            border: none;
        }

        .dropdown_content {
            opacity: 1;
            background: white;
            max-height: $items-container-max-height;
            transition: max-height 200ms ease-out;
            border-radius: 10px;
        }
    }

    .dropdown_menu_item {
        font-size: 2.4rem;
        color: $text-color-grey;
        cursor: pointer;
        border-top: 1px solid lightgrey;
        height: 49px;
        display: flex;

        &.connected {
            color: $text-color-dark-grey;

            .state_icon {
                fill: $text-color-grey;
            }
        }

        &.not_connected {
            .state_icon {
                fill: $disabled-color;
            }

            color: $disabled-color;
        }

        &:hover {
            background: $sidebar-additional-text-color;
        }
    }

    .dropdown_item_content {
        @include flexBox(inherit, center);
    }

    .controller_icon.icon_container {
        position: relative;
        width: 24px;
        height: 24px;
        margin-right: 16px;

        &:before {
            position: absolute;
            top: -6px;
            right: -6px;
            z-index: 1;
            content: '';
            border-radius: 50%;
            border: 2px solid white;
            box-sizing: content-box;
            width: 8px;
            height: 8px;
            background-color: $text-color-warning;
        }

        svg {
            position: relative;
            fill: $text-color-grey;
            height: 24px;
            width: 24px;
        }
    }

    .controller_icon.icon_container.online {
        &:before {
            background-color: $color-online;
        }

        svg {
            fill: $text-color-black;
        }
    }

    .controller__name {
        display: flex;
        flex-direction: column;
        color: $text-color-grey;
        font-size: 14px;
        line-height: 1.2;
        font-weight: 400;
        @include ellipsis();

        .status {
            margin-top: 4px;
            font-size: 13px;
            line-height: 1.2;
            color: $text-color-grey;
        }

        .status.online {
            color: $text-color-dark-grey;
        }
    }

    .controller__name.online {
        color: $text-color-black;
    }
}

.state_icon {
    display: inline-block;
    vertical-align: middle;
    width: 3rem;
    height: auto;
    margin: -0.4rem 1.4rem 0 0;
}

.dropdown_toggle {
    @include flexBox(center, center);
    height: 100%;
    padding: 0 16px;
    border: none;
    background: transparent;
}

.dropdown_arrow {
    display: none;
}

.dropdown_content {
    width: 100%;
    position: absolute;
    background: $sidebar-active-item-bg-color;
    opacity: 0;
    padding: 0;
    border-radius: 10px;
    max-height: 0;
    overflow-x: hidden;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 7px;
}
