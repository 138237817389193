@import "../../styles/variables";

.meshbot-cell {
  width: 100%;
  display: flex;
  justify-content: center;
  .serial {
    position: relative;
    .status {
      position: absolute;
      top: calc(50% - 14px);
      right: -12px;
      z-index: 1;
      border-radius: 50%;
      border: 2px solid white;
      box-sizing: content-box;
      width: 8px;
      height: 8px;
      background-color: $text-color-warning;
    }
    .status.online {
      background-color: $color-online;
    }
  }
}
