@import "../../styles/variables";

.checkbox {
  display: inline-block;
  width: 2.2rem;
  height: 2.2rem;
  margin: 0;
  cursor: pointer;

  &.checked {
    .checkbox-checkmark {
      opacity: 1;
      transition: all 250ms;
    }
  }

  &.disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &-container {
    border: .2rem solid #e5e5e5;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &-checkmark {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    top: -.4rem;
    left: -.4rem;
    fill: $base-color;
    opacity: 0;
  }
}
