@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';

.header {
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 19px 9px 5px;

    &:global(.MuiAppBar-colorPrimary) {
        background-color: $color-white;
    }

    &:global(.MuiAppBar-root) {
        display: flex;
        flex-direction: row;

        @media (min-width: $break-d-burger) {
            width: calc(100vw - 280px);
            padding: 18px 61px 5px;

            @media (max-width: 1202px) {
                transition: width 0.3s ease;
            }
        }

        @media (max-width: $break-d-xx) {
            width: 100%;
            transition: width 0.3s ease;
        }
    }
}

.logo {
    position: relative;
    top: 0;
    left: 5px;

    // TODO: avoid copy/paste, let's try to keep logo styles in a single place
    //@media (max-width: $break-t-s) {
    //display: none;
    //}
}

.tabletLogo {
    display: flex;
    align-items: center;
    left: 21px;
    top: 20px;
    margin-left: 3px;
    margin-right: 20px;

    @media (min-width: $break-d-burger) {
        display: none;
    }

    :global {
        button {
            margin-bottom: 8px;
            margin-left: 8px;
        }
    }
}

.logoIcon {
    width: 64px;
    height: 18px;
}

.logoVersion {
    color: $text-color-description;
    line-height: 1.4;
    font-size: 16px;
    font-family: Ezlo Sans;
    font-weight: 400;
    margin-left: 8px;
}

:global {
    .MuiAppBar-root {
        &:global(.MuiAppBar-positionFixed) {
            box-shadow: none;
        }
    }

    header {
        &:global(.MuiPaper-root) {
            &:global(.header_empty) {
                @media (min-width: $break-d-burger) {
                    padding: 0;
                }
            }
        }

        &:global(.MuiAppBar-root) {
            &.header_active {
                box-shadow: 0px -5px 16px 1px rgba(0, 0, 0, 0.75);
            }
        }
    }
}
