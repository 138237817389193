@import '../../../../../../styles/variables';

.deleteButton {
    &:global(.MuiButtonBase-root) {
        background-color: $text-color-warning;
        color: #ffffff;
        &:hover {
            background-color: $text-color-warning;
            box-shadow: 0 8px 16px 0 rgba(144, 160, 175, 0.3);
        }
    }
}

.linearProgress {
    &:global(.MuiLinearProgress-colorPrimary) {
        background-color: rgba(256, 92, 21, 0.2);
    }

    :global {
        .MuiLinearProgress-barColorPrimary {
            background-color: $text-color-warning;
        }
    }
}
