@import './../../../../styles/variables';

.defaultButton {
    &:global(.MuiButtonBase-root) {
        color: $text-color-black;
    }
    &:global(.MuiButton-outlined) {
        border: 1px solid rgba(0, 0, 0, 0.23);
    }
    &:global(.MuiButton-contained) {
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        background-color: #e0e0e0;
        &:hover {
            box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
                0 1px 10px 0 rgba(0, 0, 0, 0.12);
            background-color: #d5d5d5;
        }
    }
}
