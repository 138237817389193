@import "../../styles/variables";
@import "../../styles/fonts";

.dialog {

  .MuiBackdrop-root {
    background-color: rgba($text-color-black, 0.75);
  }

  .MuiDialog-scrollPaper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 100%;

    .MuiPaper-root {
      @include font-regular();
      height: calc(100% - #{$dialog-margin-top} - #{$dialog-margin-bottom});
      margin-right: 40px;
      border-radius: 24px;
      box-shadow: 0 8px 24px rgba($text-color-grey, 0.3);
      width: 440px;
    }
  }
}