@import '../../../../src/styles/variables';
@import '../../../../src/styles/mixins';

.pluginsListCount {
    position: relative;

    button {
        padding: 12px;
    }
}

.pluginsListSumPlugins {
    @include flexBox(center, center);
    position: absolute;
    right: 0;
    top: 0;
    width: 16px;
    height: 16px;
    font-size: 10px;
    background: $favorites-color;
    border-radius: 50px;
    z-index: 1;
}
