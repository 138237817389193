.selectPaper {
    ul {
        padding: 4px;
        width: 356px;
    }

    li {
        width: 100%;
        font-size: 16px;
        font-family: 'Ezlo Sans';
        margin: 0;
        height: 56px;
        padding: 4px 4px 4px 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    li:hover {
        background-color: rgba(144, 160, 175, 0.15);
        border-radius: 16px;
    }

    :global {
        .Mui-selected {
            background-color: rgba(144, 160, 175, 0.15);
            border-radius: 16px;
        }

        .Mui-selected:hover {
            background-color: rgba(144, 160, 175, 0.15);
            border-radius: 16px;
        }
    }
}
