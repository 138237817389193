@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';

.dialog {
    :global {
        .MuiPaper-rounded {
            border-radius: 0;
        }
    }
}
.dialogContainer {
    padding: 24px;
    position: relative;
    min-width: 350px;
    @media (max-width: $break-m-m) {
        min-width: auto;
    }
}
.dialogClearButton {
    position: absolute;
    top: 5px;
    right: 5px;
}

.dialogDescription {
    text-align: center;
    font-size: 18px;
    @include font-normal();
    padding: 20px 0 25px;
}

.dialogButtons {
    display: flex;
    justify-content: space-between;
    button {
        font-size: 14px;
        width: 48%;
    }
}
.warningText {
    font-size: 14px;
    @include font-medium();
    color: $text-color-black;
    display: flex;
    padding: 0px 0 25px;
    margin-top: -10px;
}
.titleText {
    font-size: 20px;
    @include font-medium();
    color: $text-color-black;
    display: flex;
    padding: 0px 0 25px;
}
