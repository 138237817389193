@import 'src/styles/variables';
@import 'src/styles/mixins';

.modalContainer {
    .closeIconContainer {
        z-index: 1000;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 36px;

        .closeIcon {
            cursor: pointer;
            width: 32px;
            height: 32px;
            display: inline-block;
        }
    }

    :global {
        .MuiPaper-root.MuiDialog-paper {
            border-radius: 16px;
            padding: 6px 6px 12px 6px;
            box-shadow: 0 8px 16px 0 rgba(144, 160, 175, 0.3);
        }
    }
}
