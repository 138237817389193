@import '../../styles/fonts';
@import '../../styles/variables';

.errorModal {
    @include font-regular();
    width: 100%;

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: Ezlo Sans;
        max-width: 400px;
        padding: 0 18px;

        h2 {
            font-size: 32px;
            line-height: 38px;
            margin: 48px 0 0;
            text-align: center;
        }

        p {
            color: rgba(0, 0, 0, 0.85);
            font-size: 20px;
            font-weight: 600;
            line-height: 23px;
            margin: 8px 0 0;
            text-align: center;
        }

        button {
            width: 100%;
            background-color: $orange-color;
            color: $color-white;
            font-weight: 400;
            height: 56px;
            margin: 48px 0 0;
            text-transform: none;
        }
    }
}
