@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '../../../../styles/fonts';

.systemHistoryWrapper {
    font-size: 16px;
    @include font-normal();
    padding: 1.5em 2.5em 1.5em;
    position: relative;
    margin: 35px 0 0 0;
    overflow: hidden;

    :global {
        .grid-table-wrapper .MuiDataGrid-root.grid-table {
            min-height: calc(100vh - 195px);
            margin: 35px 0 0 0;
        }

        .grid-table-wrapper .MuiDataGrid-root .MuiDataGrid-toolbarContainer {
            display: none;
        }

        .grid-table-wrapper .MuiDataGrid-root .MuiDataGrid-cell--textLeft {
            overflow: hidden;
        }

        .makeStyles-caption-14,
        .makeStyles-input-15 {
            display: inline-flex;
        }

        .makeStyles-input-15 {
            line-height: 60px;
        }
    }

    .searchContainer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .selectWrapper {
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
        }

        :global {
            .MuiButton-containedPrimary {
                @media (max-width: $break-t-l-expression) {
                    margin: 10px 0 0 0;
                }

                span {
                    font-size: 14px;
                }
            }
        }
    }
}
.progressBar {
    top: -30px;
    width: 100%;
    z-index: 9999;
}
