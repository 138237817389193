@import './reset';
@import './flags';
@import './variables';
@import './mixins';
@import './buttons';
@import './material';
@import './fonts';
@import '../fonts/fonts';
@import './toastify';

html {
    font-size: 10px;
    -webkit-text-size-adjust: 100%;
    /* Font varient */
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

body {
    line-height: normal;
    font-size: $main-text-font-size;
    color: $main-text-color;
    background-color: $main-bg-color;
    // todo: let's not apply width/height restrictions to body
    // because we have login, register and other pages that could be display fine on mobile, tablet screens
    //min-width: 108rem;
    //min-height: 78rem;
    font-family: 'Ezlo Sans';

    .default__input {
        height: 40px;
    }

    .notification__content {
        padding: 4px 10px 8px;
    }

    .notification__title {
        margin-top: 2px;
        margin-bottom: 2px;
    }
}

html,
body,
#app,
.page-container,
.content-wrapper {
    height: 100%;
}

body {
    margin: 0;
}

*,
:after,
:before {
    box-sizing: border-box;
    transition: color 0.25s;
}

h1 {
    font-size: $h1-font-size;
    font-weight: normal;
}

h2,
h3 {
    font-size: $h2-font-size;
    font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $text-color-black;
}

svg {
    transition: fill 0.25s;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid $divider-color;
}

a {
    color: $link-color;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: $link-hover-color;
    }

    &:active {
        color: $link-pressed-color;
    }
}

input {
    font-weight: normal;
}

b {
    font-weight: 700;
}

.icon-link {
    color: $link-color;
    fill: $link-color;
    cursor: pointer;

    &:hover {
        color: $link-hover-color;
        fill: $link-hover-color;
    }

    &:active {
        color: $link-pressed-color;
        fill: $link-pressed-color;
    }
}

.not-allowed {
    cursor: not-allowed !important;
}

.content-wrapper {
    height: 100%;
    margin-left: $navigation-width;
    padding-top: $content-wrapper-padding-top;
    transition: margin 0.3s ease;

    @media (max-width: 1200px) {
        margin-left: 0;
    }
}

.integration-container {
    height: 100%;
    margin-left: $navigation-width;
    transition: margin 0.3s ease;

    @media (max-width: 1200px) {
        margin-left: 0;
    }
}

.content-wrapper-for-customization {
    height: 100%;
    transition: margin 0.3s ease;

    @media (max-width: 1200px) {
        margin-left: 0;
        padding-top: 30px;
    }
}

.uppercase {
    text-transform: uppercase;
}

.m-15 {
    margin-left: 15px !important;
}

.icon-container {
    position: relative;
    width: $icon-container-width;
    height: $icon-container-height;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        fill: $text-color-black;
    }
}

.MuiMenu-list {
    .MuiMenuItem-root {
        font-size: 14px;
    }
}

.Toastify__toast-container {
    overflow-y: auto;
    overflow-x: clip;
    max-height: 95vh;
    width: auto;
    @include listScrollbar();
}
