@import '../../styles/variables';

.radio_button {
    width: 44px;
    height: 28px;
    input[type='radio'] {
        display: none;
    }
}

.input_label {
    display: block;
    height: 28px;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    color: $radio-button-label;
    transition: 0.3s;
    background: $color-white;
    padding: 6px 10px;
    border-radius: 2px;
    &:hover {
        cursor: pointer;
        background: $color-button-grey-hover;
    }
}

.trigger_label {
    color: $text-color-black;
    background: $color-grey;
    &:hover {
        background: darken($color-grey, 10%);
    }
}

.active {
    color: $color-white;
    background: $radio-button-bg-active;
    &:hover {
        background: $color-button-blue-hover;
    }
}
