@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';
@import '../../../../../styles/fonts';

.drawer {
    :global {
        .MuiDrawer-paper {
            position: static;
        }
        .MuiDrawer-paper {
            overflow-y: visible;
        }
    }
}

.labelsPanelContainer {
    margin-right: 16px;
    position: relative;
    &.labelsPanelOpen {
        .panelDrawer {
            width: 216px;
            border-right: 1px solid #90a0af40;
        }
        .labelsPanelButtonBox {
            position: static;
            padding: 2px;
        }
        .labelsPanelButton {
            border: none;
            border-radius: 50%;
            box-shadow: none;
            svg {
                transform: rotate(180deg);
                margin-right: 2px;
            }
        }
    }
    .panelDrawer {
        overflow: hidden;
        width: 0;
        transition: width ease-out 0.225s;
    }
    .panelDrawerContainer {
        width: 216px;
    }
    .labelsPanelButtonBox {
        z-index: 2;
        cursor: pointer;
        padding: 2px 5px 2px 0;
        transition: background-color cubic-bezier(0, 0, 0.2, 1) 225ms, left cubic-bezier(0, 0, 0.2, 1) 225ms;
        &:hover {
            left: 0;
            .labelsPanelButton {
                background-color: #f5f5f5;
            }
        }
    }

    @media (max-width: 550px) {
        margin-top: 30px;
    }
}
.labelsPanelClose {
    .labelsPanelButtonBox {
        position: absolute;
        top: 3px;
        left: -12px;
        animation: departureAnimation 300ms;
        animation-timing-function: ease-in;
    }
}
@keyframes departureAnimation {
    0% {
        left: -100px;
    }
    100% {
        left: -12px;
    }
}

.labelsPanelButton {
    width: 40px;
    height: 40px;
    border: 1px solid #90a0af40;
    border-radius: 0 25px 25px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
    svg {
        width: 7px;
        height: auto;
    }
}

.labelsPanelHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 2px 1px 16px;
    min-height: 50px;
    h3 {
        margin: 0;
        font-size: 18px;
    }
}

.listContainer {
    overflow-y: auto;
    @include listScrollbar();
    max-height: calc(100vh - 190px);
    @media (max-width: 550px) {
        max-height: calc(100vh - 160px);
    }
}
.bodyContainer {
    height: calc(100vh - 128px);
    @media (max-width: 550px) {
        height: calc(100vh - 100px);
    }
}
