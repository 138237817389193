@import '../../../../../styles/mixins.scss';

.wrapper {
    .select {
        @include selectStyle();
    }

    .select_small {
        min-width: 80px;
    }
}

.configureSelectWrapper label {
    top: -6px !important;
}

.MuiPopover_paper {
    @include inputScrollbar;
    @include heightOfSelect;
}

h4 {
    &:first-letter {
        text-transform: uppercase;
    }
}

.errorText {
    &:global(.MuiFormHelperText-root) {
        font-size: 10px;
        font-family: 'Ezlo Sans';
    }
}
