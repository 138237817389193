@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';

.dialog {
    :global {
        .MuiPaper-rounded {
            border-radius: 24px;
        }
    }
}
.dialogContainer {
    width: 436px;
    padding: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.title {
    font-size: 26px;
    @include font-semibold();
    margin-bottom: 12px;
    text-align: center;
}
.subTitle {
    font-size: 16px;
    @include font-regular();
    text-align: center;
}
.buttonContainer {
    margin: 28px 0 16px 0;
    width: 100%;
}
.closeIconContainer {
    position: absolute;
    top: 26px;
    left: 26px;
    svg {
        width: 22px;
        height: 20px;
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
    }
}
.mainIconContainer {
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0 5px 0;

    svg {
        height: 84px;
        width: auto;
    }
}
.linearProgress {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
