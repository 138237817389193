@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins.scss';

.function_block {
    @include flexBox(inherit, center);
    position: relative;
    margin-left: auto;
    .info_button_wrapper {
        :global {
            .MuiIconButton-root {
                width: 23px;
                height: 23px;
                z-index: 0;
                right: -17px;
                position: absolute;
                top: -6px;
                color: $info-btn-color;
                .MuiSvgIcon-root {
                    path {
                        fill: $info-btn-color;
                    }
                }
            }
        }
    }
    .move_right {
        :global {
            .MuiIconButton-root {
                right: -13px;
            }
        }
    }
    .function_button {
        border: none;
        background-color: $color-grey;
        border-radius: 2px;
        z-index: 0;
        width: $functionBtnWidth;
        height: 44px;

        &:hover {
            cursor: pointer;
            background-color: $color-grey;
        }
        svg {
            path {
                fill: $meshbot-trigger-section;
            }
        }
    }
    .margin_right {
        margin-right: 4px;
    }
    .function_button_settings {
        width: auto;
        padding: 12px 0 12px 26px;
        margin-right: 20px;
    }
    &.unselected {
        svg {
            path {
                fill: $text-color-grey;
            }
        }
    }
}

.label {
    @include flexBox(center, center);
    margin-right: 16px;
    color: $text-color-grey;
    @include font-medium();
    font-size: 13px;
    white-space: nowrap;
}

.description {
    min-width: 200px;
    padding-bottom: 4px;
}

.function_block__settings {
    @include flexBox(inherit, center);
    background-color: $color-grey;
    min-width: 149px;
    min-height: 44px;
    border-left: none;
    position: relative;
    z-index: 10;
    border-radius: 2px;

    :global {
        .MuiSelect-select {
            font-size: 13px;
            color: $text-color-black;
            @include font-medium();
        }
        label + .MuiInputBase-formControl {
            margin-top: 0;
            min-height: 27px;
        }
        .MuiInputLabel-root {
            display: none;
        }
    }
}

.function_block__select {
    margin-right: 16px;
    min-height: 27px;
    margin-bottom: 5px;
}

.not_selected {
    :global {
        .MuiSelect-select {
            color: $text-color-dark-grey;
        }
    }
}

.function_block__inner {
    width: 100%;
    margin-bottom: 5px;
}

.group_header_place {
    background-color: transparent;
    margin-top: 5px;
}
