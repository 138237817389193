@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '../../../../styles/fonts';

.systemHistoryWrapper {
    font-size: 16px;
    @include font-normal();
    padding: 3.5em 2.5em 1.5em;
    height: 100%;
    display: flex;
    flex-direction: column;

    .searchContainer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .selectWrapper {
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
        }

        :global {
            .MuiButton-containedPrimary {
                @media (max-width: $break-t-l-expression) {
                    margin: 10px 0 0 0;
                }

                span {
                    font-size: 14px;
                }
            }
        }
    }
    .deviceIcon {
        width: 28px;
        fill: $text-color-black;
    }
}
.progressBar {
    top: -30px;
    width: 100%;
    z-index: 9999;
}
