@import '../../styles/variables';

.formInput {
    position: relative;
    margin: 0 0 2.6rem 0;

    &:global(.error) {
        input {
            border-color: $danger-color;
        }

        &:focus {
            border-color: $danger-color;
        }
    }

    &:global(.password-input) {
        input {
            padding: 1rem 4.5rem 1rem 2rem;
        }
    }

    :global {
        label {
            display: block;
            max-width: 100%;
            margin-bottom: 5px;
            color: $text-color-grey;
            font-size: 14px;
        }

        input {
            width: 100%;
            margin: 0;
            font-size: $field-font-size;
            padding: 1rem 2rem;
            line-height: normal;
            border: 1px solid $border-color;
            border-radius: 4px;
            background-color: $color-white;
            box-shadow: inset 0 2px 2px 0 $divider-color;
            outline: none;
            color: $additional-text-color;

            &[readonly],
            &[readonly]:focus,
            &[disabled] {
                cursor: not-allowed;
                border-color: $disabled-color;
            }
        }
    }
}

.error {
    &:global(.formInput) {
        input {
            border-color: $danger-color;
        }

        &:focus {
            border-color: $danger-color;
        }
    }
}

.showPasswordBtn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    user-select: none;

    &:global(.disabled) {
        .eyeIcon {
            fill: $icon-link-color;
        }
    }

    &:global(.password-showed) {
        .eyeIcon {
            fill: $base-color-hover;
        }
    }
}

.eyeIcon {
    width: 100%;
    fill: $icon-link-color;
    cursor: pointer;

    &:hover {
        fill: $base-color-hover;
    }

    &:active {
        fill: $base-color-active;
    }
}
