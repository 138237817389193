@import '../../styles/fonts';
@import '../../styles/variables.scss';

.tooltip-popper {
    .MuiTooltip-tooltip {
        background-color: #fff;
        color: black;
        border-radius: 0.4rem;
        z-index: 20;
        box-shadow: 0 0 6px 2px #0c0c0c66;
        text-transform: none;
        font-size: 1.4rem;
        line-height: 1.8rem;
    }

    .MuiTooltip-arrow {
        color: #fff;
    }
}
