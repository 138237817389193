@import '../../styles/variables.scss';

.meshbotDuplicateWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    svg {
        font-size: 18px;
    }
    .disabled {
        opacity: 0.5;
        pointer-events: none;
        svg {
            fill: $color-button-grey-1;
        }
    }
}
