@import "../../styles/variables";

.toggle {
  display: inline-block;
  height: 2.2rem;
  width: 4.8rem;
  cursor: pointer;

  &.toggled {
    .toggle-handle {
      transform: translateX(2.5rem);
      background: #fff;
      transition: all 250ms;
    }

    .toggle-container {
      background-color: $toggle-color;
    }
  }

  &.disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &-container {
    position: relative;
    height: 2.2rem;
    width: 4.8rem;
    border-radius: 6rem;
    border: 1px solid $toggle-color;
  }

  &-handle {
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    top: 2px;
    left: 2px;
    border-radius: 100%;
    background: $toggle-color;
    transition: all 250ms;
  }

  &.sidebar {
    &.toggled {
      .toggle-handle {
        background: #fff;
      }

      .toggle-container {
        background-color: $sidebar-toggle-color;
      }
    }

    .toggle-container {
      border: 1px solid $sidebar-toggle-color;
    }

    .toggle-handle {
      background: $sidebar-toggle-color;
    }
  }
}
