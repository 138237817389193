@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/fonts';

$menu-item-height: 44px;

.navigation {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: $navigation-width;
    background-color: $color-white;
    color: $text-color-dark-grey;
    padding-top: $navigation-logo-margin;

    &__img {
        max-height: $navigation-logo-height;
        max-width: 180px;
        width: auto;
        height: auto;
    }

    .logo-container {
        margin-left: 22px;
        margin-bottom: 10px;
    }

    .navigation-logo {
        display: flex;
        flex-direction: column;
        width: 180px;
        margin-left: 10px;
        svg {
            fill: $color-black;
        }
    }
    .mobile-width {
        margin: auto;
        svg {
            fill: $color-black;
        }
    }

    svg {
        fill: $text-color-dark-grey;
    }

    .menu-container {
        margin-top: 20px;
        padding-bottom: 90px;
        width: 100%;
        overflow: scroll;
        padding-left: 6px;
        @include listScrollbar();

        .menu {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0;

            .menu-item {
                border-radius: 4px;
            }

            .submenu__item {
                svg {
                    position: absolute;
                    right: 15px;
                }
            }

            .submenu__item_active .MuiTypography-body1 {
                color: $text-color-blue;
            }

            .MuiTypography-body1 {
                font-size: $menu-item-font-size;
                @include font-medium();
                text-align: left;
            }

            .menu-item {
                padding: 10px 15px 10px 15px;
            }

            .submenu__item {
                padding: 10px 15px 10px 50px;
            }

            .menu-item,
            .submenu__item {
                position: relative;
                display: flex;
                align-items: center;
                height: $menu-item-height;
                text-align: left;
                cursor: pointer;
                border-radius: 4px;
                @include font-medium();
                font-size: $menu-item-font-size;
                line-height: $menu-item-line-height;
                svg {
                    fill: $color-navigation-menu-text;
                }
                &:hover {
                    @include transparentBackgroundColorMixin(0.05, #3e46ae);
                }

                &.checked {
                    color: $text-color-blue;
                    @include transparentBackgroundColorMixin(0.1, #3e46ae);

                    svg {
                        fill: $text-color-blue;
                    }
                }

                &.sub_title_checked {
                    color: $text-color-blue;
                    @include transparentBackgroundColorMixin(0.1, #3e46ae);
                }

                &.sub_checked {
                    color: $text-color-blue;

                    svg {
                        fill: $text-color-blue;
                    }
                }

                .menu-item__icon {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-right: 13px;
                    width: 22px;
                    height: 22px;
                }

                .menu-item__title {
                    flex-grow: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 80%;

                    .skeleton-title {
                        width: 100%;
                        background: rgba($text-color-grey, 0.25);
                        height: 32px;
                        border-radius: 4px;
                    }
                }

                .sub_menu-item_title {
                    flex-grow: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 90%;
                }

                .sub_menu_sub-item_title {
                    flex-grow: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 90%;
                }
                .menu-item__arrow {
                    transform: rotate(0);
                    margin-left: auto;
                    margin-right: 4px;
                    height: 8px;
                    width: 12px;
                }

                .menu-item__arrow_down {
                    transform: rotate(180deg);
                }

                .skeleton-icon {
                    background: rgba($text-color-grey, 0.25);
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                }
            }

            .menu-item_active {
                color: $text-color-black;

                svg {
                    fill: $text-color-dark-grey;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 6px;
                    border-top-right-radius: 6px 10%;
                    border-bottom-right-radius: 6px 10%;
                    height: 100%;
                    background: $text-color-black;
                }
            }

            .menu-item_loading {
                cursor: auto;
                animation: skeleton-loading 2s infinite;

                &:hover {
                    background-color: transparent;
                }
            }

            .menu-item_submenu {
                background-color: $color-white;

                .menu-item__icon {
                    opacity: 0;
                }

                &.menu-item_active {
                    background-color: rgba(144, 160, 175, 0.15);
                    color: #1c1e21;
                }

                &:before {
                    opacity: 0;
                }
            }

            .white {
                background: $color-white;
            }
        }

        .submenu {
            background: $color-white;
            width: 100%;

            .submenu__item {
                padding-left: 64px;

                & > div > span {
                    font-size: 14px;
                    line-height: 23px;
                    @include font-regular();
                }

                .MuiTypography-body1 {
                    padding: 0 0 0 0;
                }

                &:hover {
                    @include transparentBackgroundColorMixin(0.05, #3e46ae);
                }
            }

            .submenu__item-level2 {
                padding-left: 85px;
            }

            .submenu__item_active {
                color: $text-color-blue;
            }
        }
    }

    .bottom-items-wrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0 6px 8px;
        background: $color-white;

        .logout,
        .support {
            height: $menu-item-height;
            padding: 12px 15px 12px 15px;
            text-align: center;
            cursor: pointer;
            @include font-medium();
            font-size: $menu-item-font-size;
            line-height: $menu-item-line-height;
            background-color: $color-white;
            border-radius: 4px;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 4px;
            }

            &:hover {
                &:after {
                    @include transparentBackgroundColorMixin(0.05, #3e46ae);
                }
            }

            .menu-item__icon {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-right: 13px;
            }
            .menu-item__svgicon {
                svg {
                    fill: $color-navigation-menu-text;
                }
            }

            .menu-item__version {
                font-size: 13px;
                position: absolute;
                right: 12px;
                z-index: 1;
            }
        }
    }
}

.drawwer-tablet {
    position: absolute !important;
    top: 20px;
    left: 20px;
}

.MuiDrawer-paper {
    @media (min-width: $break-d-xx) {
        box-shadow: none !important;
    }

    .MuiCollapse-root {
        transition-duration: 270ms;
    }
}
