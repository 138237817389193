@import '../../styles/variables';

@keyframes draw {
    0% {
        stroke-dasharray: 1px, 200px;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -15px;
    }
    100% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -125px;
    }
}
@keyframes transformRotate {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.animatedCircular {
    fill: transparent;
    stroke: currentColor;
    stroke-width: 3;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0;
    animation: draw 1.4s infinite ease-in-out;
    -webkit-animation: draw 1.4s infinite ease-in-out;
}
.circularProgressBox {
    width: 100%;
    height: 100%;
    display: inline-block;
    animation: transformRotate 1.4s infinite ease-in-out;
    -webkit-animation: transformRotate 1.4s infinite ease-in-out;
}

.container {
    position: relative;
    width: 40px;
    height: 40px;
    svg {
        position: absolute;
        left: 0;
    }
}
