@import '../../styles/variables';

.lineProgress {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1200;
    top: -$content-wrapper-padding-top;
}
.lineProgressTop {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1200;
    top: -40px;
}
.lineProgressBox {
    position: relative;
}
.lineProgressBoxWrapperTop {
    position: relative;
    top: -30px;
}
